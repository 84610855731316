<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="数据量统计" width="90%" top="5vh" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <vxe-table
          border
          :column-config="{resizable: true}"
          :tree-config="{transform: true, rowField: 'id', parentField: 'mainId', expandAll: true, reserve: true}"
          :row-config="{keyField: 'id'}"
          :max-height="maxTableHeight"
          :data="dataList">
        <vxe-column field="name" title="菜单" tree-node fixed="left"></vxe-column>
        <vxe-column v-for="project in projectList" :key="project.id" :field="project.id" :title="project.name" align="right" :formatter="numFormatter"></vxe-column>
        <vxe-column field="-1" align="right">
          <template #header>
            <span>其他</span>
            <el-tooltip effect="light" content="主表已删除，但明细数据扔存在的情况" placement="bottom">
              <i class='el-icon-question' />
            </el-tooltip>
          </template>
        </vxe-column>
        <vxe-column field="total" title="总计" align="right" width="100" :formatter="numFormatter"></vxe-column>
        <vxe-column title="操作" width="120" align="center" fixed="right">
          <template #default="{row, $rowIndex}">
            <el-button type="text" size="small" @click="clearData(row, $rowIndex)">清空数据</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import XEUtils from 'xe-utils'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        tenantCode: '',
      },
      dataList: [],
      projectList: [],
      tableLoading: false,
      maxTableHeight: 400,
    }
  },
  computed: {
    dataRule () {
      return {
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      //  表格高度设置
      this.maxTableHeight = window.innerHeight - 220
    })
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getInfo()
      })
    },
    // 获取信息
    getInfo () {
      // 查询租户内项目列表
      this.$http.get('/mps/project/tenant/' + this.dataForm.tenantCode).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.projectList = res.data
      }).catch(() => {})
      // 查询租户内数据量统计
      this.$http.get('/opm/dataStatistics/tenantView/' + this.dataForm.tenantCode).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
      }).catch(() => {})
    },
    // 清空选中的功能的数据
    clearData(row, rowIndex) {
      this.$confirm('将清空租户下对应功能的全部数据，确定继续吗？', this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        // 有mainId说明是明细行，没有mainId说明是主表行
        if (row.mainId) {
          this.$http.delete(
              '/opm/dataStatistics/dataMx',
              {
                data: {
                  id: row.id,
                  tenantCode: this.dataForm.tenantCode
                }
              }
          ).then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '清空成功',
              type: 'success',
              duration: 2000,
            })
            this.$set(this.dataList, rowIndex, {
              id: row.id,
              mainId: row.mainId,
              name: row.name,
              total: 0
            })
          }).catch(() => {})
        } else {
          this.$http.delete(
              '/opm/dataStatistics/data',
              {
                data: {
                  id: row.id,
                  tenantCode: this.dataForm.tenantCode
                }
              }
          ).then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '清空成功',
              type: 'success',
              duration: 2000,
            })
            let mainId = row.id
            this.$set(this.dataList, rowIndex, {
              id: row.id,
              mainId: row.mainId,
              name: row.name,
              total: 0
            });
            for (let i = rowIndex + 1; i < this.dataList.length; i++) {
              let currentRow = this.dataList[i]
              if (currentRow.mainId == mainId) {
                this.$set(this.dataList, i, {
                  id: currentRow.id,
                  mainId: currentRow.mainId,
                  name: currentRow.name,
                  total: 0
                });
              } else {
                break
              }
            }
          }).catch(() => {})
        }
      }).catch(() => {
        // do nothing
      })
    },

    // 数量格式化
    numFormatter ({cellValue}) {
     return XEUtils.commafy(cellValue)
    },
  }
}
</script>