import { render, staticRenderFns } from "./annualschedule-add-or-update.vue?vue&type=template&id=3fef1f8b"
import script from "./annualschedule-add-or-update.vue?vue&type=script&lang=js"
export * from "./annualschedule-add-or-update.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports